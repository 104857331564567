import React, { useEffect, useState } from "react";
// import ReactLoading from "https://cdn.skypack.dev/react-loading@2.0.3";
import { ThreeDots } from "react-loader-spinner";
import {
  DLUABI,
  DLUContractAddress,
  StackingABI,
  StackingAddress,
} from "./../config";
import Web3 from "web3";
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useAccount } from "wagmi";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
const people = [
  { name: "6 Months", value: "0" },
  { name: "12 Months", value: "1" },
];
const Stacking = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [stakeLoader, setStakeLoader] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(0);
  const [userStake, setUserStake] = useState();
  const [selectedOption, setSelectedOption] = useState(people[0]);
  const handleListboxChange = (selected) => {
    setSelectedOption(selected);
  };
  let web3 = new Web3(window.ethereum);
  let StackingContract = new web3.eth.Contract(StackingABI, StackingAddress);
  let DLUContract = new web3.eth.Contract(DLUABI, DLUContractAddress);

  const { address, isDisconnected } = useAccount();
  const [amount, setAmount] = useState(null);

  const callWriteFunction = async (index) => {
    try {
      setLoader(true);
      const unstake = StackingContract.methods.Unstake(index);
      let encoded_tx = unstake.encodeABI();
      let gasPrice = await web3.eth.getGasPrice();
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: StackingAddress,
        from: address,
        data: encoded_tx,
      });
      let trx = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: StackingAddress,
        from: address,
        data: encoded_tx,
      });
      setLoader(false);
    } catch (error) {
      setLoader(false);
      let err = error.data ? error.data.message : error.message;
      alert(err);
      console.log(err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setStakeLoader(true);
      let weiTokenAmount = web3.utils.toWei(amount, "ether");
      console.log(
        "StackingAddress, amount : ",
        StackingAddress,
        weiTokenAmount
      );
      const tokenApprove = DLUContract.methods.approve(
        StackingAddress,
        weiTokenAmount
      );
      const stakePool = StackingContract.methods.stakePool(
        weiTokenAmount,
        selectedOption.value
      );

      let encoded_tx = tokenApprove.encodeABI();
      let gasPrice = await web3.eth.getGasPrice();
      console.log("gasPrice : ", gasPrice);
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: DLUContractAddress,
        from: address,
        data: encoded_tx,
      });
      let trx = await web3.eth
        .sendTransaction({
          gasPrice: web3.utils.toHex(gasPrice),
          gas: web3.utils.toHex(gasLimit),
          to: DLUContractAddress,
          from: address,
          data: encoded_tx,
        })
        .then(async (res) => {
          console.log("rahul");
          let encoded_tx = stakePool.encodeABI();
          let gasPrice = await web3.eth.getGasPrice();
          console.log("gasPrice : ", gasPrice);
          let gasLimit = await web3.eth.estimateGas({
            gasPrice: web3.utils.toHex(gasPrice),
            to: StackingAddress,
            from: address,
            data: encoded_tx,
          });
          let trx = await web3.eth.sendTransaction({
            gasPrice: web3.utils.toHex(gasPrice),
            gas: web3.utils.toHex(gasLimit),
            to: StackingAddress,
            from: address,
            data: encoded_tx,
          });
        });
      setStakeLoader(false);
    } catch (error) {
      setStakeLoader(false);
      let err = error.data ? error.data.message : error.message;
      alert(err);
      console.log(err);
    }
  };

  const convertDateTime = (epochTimestamp) => {
    // Convert the epoch timestamp to milliseconds
    const timestampInMilliseconds = epochTimestamp * 1000;

    // Create a Date object from the timestamp
    const date = new Date(timestampInMilliseconds);

    // Format the date using date-fns
    const formattedDateTime = format(date, "dd-MMM-yyyy");

    // Return Format
    return formattedDateTime;
  };
  const getUserStakingList = async () => {
    setIsLoading(true);
    const userStakeValue = await StackingContract.methods
      .getUserStake(address)
      .call();
    setUserStake(userStakeValue);
    setIsLoading(false);
  };

  useEffect(() => {
    getUserStakingList();
  }, [address]);

  const handleNavigate = () => {
    navigate(-1);
  };

  return (
    <div className="max-w-[500px] w-full mx-auto register ">
      <div className="bg-gradient-to-r from-[#04021F] to-[#280941] text-white min-h-screen ">
        <div className="flex gap-2 ">
          <img
            src={process.env.PUBLIC_URL + "/assets/left.png"}
            className="cursor-pointer"
            onClick={handleNavigate}
          />

          <h2 className="text-white text-center font-bold ">Stacking Plan</h2>
        </div>

        <form
          className=" mx-auto max-w-[450px] py-5 px-3"
          onSubmit={handleSubmit}
        >
          <div className="my-5">
            <label className="pb-3">Choose the staking plan for you</label>
            <br />
            <input
              type="text"
              placeholder="Amount"
              className="rounded-lg px-5 py-3 w-full my-4"
              onChange={(e) => setAmount(e.target.value)}
            ></input>
          </div>

          <div className="my-5 py-5">
            <label className="pb-3">Select a Plan</label>
            <br />
            <Listbox
              value={selectedOption.value}
              onChange={handleListboxChange}
            >
              <div className="relative mt-1">
                <Listbox.Button
                  className="relative w-full cursor-default rounded-lg bg-white py-3 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
                >
                  <span className="block truncate text-[#9CA39A]">
                    {selectedOption.name}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {people.map((person, personIdx) => (
                      <Listbox.Option
                        key={personIdx}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                            active
                              ? "bg-[#FFFFFF33] text-[#9CA39A]"
                              : "text-gray-900"
                          }`
                        }
                        value={person}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {person.name}
                            </span>
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>

          <div className="text-center py-5">
            <button
              type="submit"
              //   onClick={handleRegister}
              className="text-white  px-3 max-w-[300px] w-full mx-auto py-3 rounded-full  bg-gradient-to-r from-[#157AC8] to-[#BC28D3] font-bold"
            >
              {stakeLoader ? "Processing...." : "Stake Now"}
            </button>
          </div>
        </form>
        <div className="flex gap-2 flex-wrap  items-center">
          {userStake &&
            userStake.map((item, index) =>
              !item.Claimed ? (
                <div className="px-5 py-2 rounded-2xl bg-[#343D55]" key={index}>
                  <div className="info-table py-5">
                    <table className="text-white w-full mx-auto py-2">
                      <tbody>
                        <tr>
                          <th className="text-left font-light border-0">
                            APR:
                          </th>
                          <td className="text-end border-0">
                            {(item.aprPercent / 100).toFixed(2)}%
                          </td>
                        </tr>
                        <tr>
                          <th className="text-left font-light border-0">
                            Min:
                          </th>
                          <td className="text-end border-0 gap-2 items-center">
                            5
                          </td>
                        </tr>
                        <tr>
                          <th className="text-left font-light border-0">
                            Amount:
                          </th>
                          <td className="text-end border-0 gap-2 items-center">
                            {(item.Amount / Math.pow(10, 18)).toFixed(0)}
                          </td>
                        </tr>
                        <tr>
                          <th className="text-left font-light border-0">
                            Duration:
                          </th>
                          <td className="text-end border-0 gap-2 items-center">
                            {item.Duration == 0 ? "06 Month" : "12 Month"}
                          </td>
                        </tr>

                        {/* Uncomment if needed */}
                        {/* <tr>
                  <th className="text-left font-light border-0">Max:</th>
                  <td className="text-end border-0 gap-2 items-center">
                    500 DLU
                  </td>
                </tr> */}

                        <tr>
                          <th className="text-left font-light border-0">
                            Started:
                          </th>
                          <td className="text-end border-0 gap-2 items-center">
                            {convertDateTime(item.StakeTime)}
                          </td>
                        </tr>
                        <tr>
                          <th className="text-left font-light border-0">
                            Ended:
                          </th>
                          <td className="text-end border-0 gap-2 items-center">
                            {convertDateTime(item.MaturityTime)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="text-center mt-4">
                      <button
                        onClick={() => {
                          callWriteFunction(index);
                          setClickedIndex(index);
                        }}
                        className="text-white px-3 max-w-[300px] w-full mx-auto py-2 rounded-full bg-gradient-to-r from-[#157AC8] to-[#BC28D3] font-bold"
                      >
                        {loader && clickedIndex == index
                          ? "Processing...."
                          : "Claim Now"}
                      </button>
                    </div>
                  </div>
                </div>
              ) : null
            )}
        </div>

        <div className="flex flex-col mt-10">
          <div className="overflow-x-auto">
            <div className=" w-full inline-block align-middle">
              <div className="overflow-scroll md:overflow-auto border info-table text-white rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <tbody className="divide-y divide-gray-200">
                    <tr>
                      <td className="   px-3 py-3 text-sm font-medium  border-r text-white whitespace-nowrap">
                        SR. No.
                      </td>
                      <td className="   px-3 py-3 text-sm text-white  border-r  whitespace-nowrap">
                        APR Percent
                      </td>
                      <td className="   px-3 py-3 text-sm text-white  border-r  whitespace-nowrap">
                        Amount
                      </td>
                      <td className="   px-3 py-3 text-sm text-white  border-r  whitespace-nowrap">
                        Duration
                      </td>
                      <td className="   px-3 py-3 text-sm text-white  border-r  whitespace-nowrap">
                        Claimed
                      </td>
                      <td className="   px-3 py-3 text-sm text-white  border-r  whitespace-nowrap">
                        StakeTime
                      </td>
                      <td className="   px-3 py-3 text-sm text-white  border-r  whitespace-nowrap">
                        MaturityTime
                      </td>
                    </tr>

                    {userStake ? (
                      userStake.map((item, key) => (
                        <tr key={item.id}>
                          <td className="px-3 py-3 text-sm font-medium border-r text-white whitespace-nowrap">
                            {key + 1}
                          </td>
                          <td className="px-3 py-3 text-sm text-white border-r whitespace-nowrap">
                            {(item.aprPercent / 100).toFixed(2)}%
                          </td>
                          <td className="px-3 py-3 text-sm text-white border-r whitespace-nowrap">
                            {(item.Amount / Math.pow(10, 18)).toFixed(2)}
                          </td>
                          <td className="px-3 py-3 text-sm text-white border-r whitespace-nowrap">
                            {item.Duration == 0 ? "06 Month" : "12 Month"}
                          </td>
                          <td className="px-3 py-3 text-sm text-white border-r whitespace-nowrap">
                            {item.Claimed == true ? (
                              <p className="text-green">Complete</p>
                            ) : (
                              <p className="text-red">Pending</p>
                            )}
                          </td>
                          <td className="px-3 py-3 text-sm text-white border-r whitespace-nowrap">
                            {convertDateTime(item.StakeTime)}
                          </td>
                          <td className="px-3 py-3 text-sm text-white border-r whitespace-nowrap">
                            {convertDateTime(item.MaturityTime)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4">Loading data...</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="flex max-w-[350px] h-screen w-[500px] items-center justify-center z-50">
            {/* <ReactLoading
              type={"bars"}
              color={"#2373c9"}
              height={100}
              width={100}
            /> */}
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="white"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Stacking;
