import React, { useEffect, useState, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
// import ReactLoading from "https://cdn.skypack.dev/react-loading@2.0.3";
import { ThreeDots } from "react-loader-spinner";
import {
  MainContractABI,
  MainContractAddress,
  StackingABI,
  StackingAddress,
} from "../config";
import Web3 from "web3";
import copy from "copy-to-clipboard";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import { Popup } from "./Popup/Popup.js";

const HomeComponent = ({ text }) => {
  const gradientStyles = {
    background: "linear-gradient(to right, #157AC8, #BC28D3)",
  };
  const [isLoading, setIsLoading] = useState(false);
  const textAreaRef = useRef(null);
  const [isUserExist, setIsUserExist] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const [userStakeInfo, setUserStakeInfo] = useState("");
  const [fee, setFee] = useState("");
  const [pairMatchClaimeds, setPairMatchClaimeds] = useState("");
  const [pairMatchReward, setPairMatchReward] = useState(0);
  const [childCount, setChildCount] = useState("");
  const { address, isDisconnected } = useAccount();
  const navigate = useNavigate();

  let web3 = new Web3(window.ethereum);
  let MainContract = new web3.eth.Contract(
    MainContractABI,
    MainContractAddress
  );
  let StackingContract = new web3.eth.Contract(StackingABI, StackingAddress);

  const handlePairMatchConfirm = async () => {
    setIsLoading(true);

    // if (parseFloat((await checkApproval()) / Math.pow(10, 18) < 20)) {
    try {
      const matchPairClaim = MainContract.methods.MatchPairClaim();

      let encoded_tx = matchPairClaim.encodeABI();
      let gasPrice = await web3.eth.getGasPrice();
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: MainContractAddress,
        from: address,
        data: encoded_tx,
      });
      let trx = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: MainContractAddress,
        from: address,
        data: encoded_tx,
      });
    } catch (error) {
      {
        setIsLoading(false);
        let err = error.data ? error.data.message : error.message;
        alert(err);
        console.log(err);
      }
    }
    setShowConfirmation(false);
    setIsLoading(false);
  };

  const handleCancelClick = () => {
    setShowConfirmation(false);
  };

  //fetch data about the  token
  const getData = async () => {
    setIsLoading(true);
    try {
      const isReferralLink = await MainContract.methods
        .isReferralLink(address)
        .call();
      setIsUserExist(isReferralLink);
      // get username
      let user = await MainContract.methods.User(address).call();
      setUserInfo(user);

      let fee = await MainContract.methods.getFee().call();
      setFee(fee);

      let childCount = await MainContract.methods
        .getChildCounts(address)
        .call();
      setChildCount(childCount);

      let stakeLeftRight = await StackingContract.methods
        .getLeftRightStaking(address)
        .call();
      setUserStakeInfo(stakeLeftRight);

      let pairMatchClaim = await MainContract.methods
        .pairMatchClaimeds(address)
        .call();
      setPairMatchClaimeds(pairMatchClaim);
      console.log("pairMatchClaim 89 : ", pairMatchClaim);

      // set pair match reward
      let pairMatchReward = await MainContract.methods
        .calculateMatching(address)
        .call();
      setPairMatchReward(pairMatchReward);
      console.log("pairMatchReward 89 : ", pairMatchReward);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
    setIsLoading(false);
  };

  const [copyText, setCopyText] = useState("");

  const handleCopyText = (address) => {
    setCopyText(address);
  };

  const copyToClipboard = () => {
    copy(address);
    alert("copied");
  };

  // useEffect(async () => {
  //   getData();
  //   if (isUserExist) {
  //     navigate("/main");
  //   } else {
  //     navigate("/register");
  //   }
  // }, [address, isDisconnected, pairMatchClaimeds]);

  // useEffect(async () => {
  //   // Call the async function to fetch data
  //   getData();

  //   var isReferralLink = await MainContract.methods
  //     .isReferralLink(address)
  //     .call();
  //   if (isReferralLink) {
  //     navigate("/main");
  //   } else {
  //     navigate("/register");
  //   }
  // }, [address, isDisconnected, pairMatchClaimeds, isUserExist]);

  useEffect(() => {
    const fetchData = async () => {
      await getData();

      const isReferralLink = await MainContract.methods
        .isReferralLink(address)
        .call();
      if (isReferralLink) {
        navigate("/main");
      } else {
        navigate("/register");
      }
    };

    fetchData();
  }, [address, isDisconnected, pairMatchClaimeds, isUserExist]);

  {
    console.log("isUserExist : ", isUserExist);
  }

  return (
    <>
      <div>
        <img
          src={process.env.PUBLIC_URL + "/assets/profile.png"}
          className="w-100 mx-auto max-w-[300px]"
        />
      </div>
      <div className="px-5 py-5 my-5 rounded-2xl  bg-gradient-to-r from-[#157AC8] to-[#BC28D3]">
        <h6 className="text-lg text-white font-bold  text-center">
          My Information
        </h6>
        <div className="info-table py-5">
          <table className="text-white w-full mx-auto py-2">
            <tr>
              <th className="text-left  font-light border-0">Name:</th>
              <td className="text-left border-0">
                {" "}
                {userInfo && userInfo ? userInfo?.username : "Loading..."}
              </td>
            </tr>
            <tr>
              <th className="text-left font-light border-0">Wallet Address:</th>

              <td className="flex text-left border-0 gap-2 items-center">
                <input
                  ref={textAreaRef}
                  value={address}
                  onChange={handleCopyText}
                  readOnly
                  className="opacity-0 w-0 h-0 absolute"
                />
                {address?.slice(0, 4) + "****" + address?.slice(-6)}
                <img
                  src={process.env.PUBLIC_URL + "/assets/copy.svg"}
                  onClick={copyToClipboard}
                  className="ml-2 cursor-pointer"
                  alt="Copy Address"
                />
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div className="flex flex-wrap gap-5 justify-center items-center">
        <div className="min-h-[100px] md:min-h-[200px] min-w-[200px] md:min-w-[200px] flex-1 border border-[#C026D3] rounded-lg py-5 px-5 bg-[#00011B]">
          <img
            src={process.env.PUBLIC_URL + "/assets/R1.svg"}
            className="max-w-[80px] w-full mx-auto "
          />
          <h1 className="text-white text-center text-lg py-5">
            {userInfo.direct !== undefined
              ? userInfo.direct / Math.pow(10, 18).toFixed(2)
              : 0}
          </h1>
          <div className="text-center">
            <div
              onClick={() => {
                navigate("/direct-reward");
              }}
              className="bg-gradient-to-r from-[#157AC8] to-[#BC28D3] rounded-full text-white px-5 text-sm py-3 cursor-pointer"
            >
              Direct Reward
            </div>
          </div>
        </div>
        <div className=" min-h-[100px] md:min-h-[200px] min-w-[200px] md:min-w-[200px] flex-1 border border-[#C026D3] rounded-lg py-5 px-5 bg-[#00011B]">
          <img
            src={process.env.PUBLIC_URL + "/assets/R2.svg"}
            className="max-w-[80px] w-full mx-auto "
          />
          <h1 className="text-white text-center text-lg py-5">
            {userInfo.level !== undefined
              ? (userInfo.level / Math.pow(10, 18)).toFixed(2)
              : 0}
          </h1>
          <div className="text-center">
            {/* <NavLink to="/level-reward"> */}
            <div
              onClick={() => {
                navigate("/level-reward");
              }}
              className="bg-gradient-to-r from-[#157AC8] to-[#BC28D3] rounded-full text-white px-5 text-sm py-3 cursor-pointer"
            >
              Level Reward
            </div>
            {/* </NavLink> */}
          </div>
        </div>
        <div className="  min-h-[100px] md:min-h-[200px] min-w-[200px] md:min-w-[200px]  flex-1 border border-[#C026D3] rounded-lg py-5 px-5 bg-[#00011B]">
          <img
            src={process.env.PUBLIC_URL + "/assets/R3.svg"}
            className="max-w-[80px] w-full mx-auto "
          />
          <h1 className="text-white text-center text-lg py-5">
            {pairMatchReward !== undefined
              ? `${((12 * 5) / 100) * pairMatchReward}(${
                  (pairMatchReward - pairMatchClaimeds) * ((12 * 5) / 100)
                })`
              : 0}
          </h1>
          <div className="text-center">
            <button
              onClick={() => setShowConfirmation(true)}
              className="bg-gradient-to-r from-[#157AC8] to-[#BC28D3] rounded-full text-white px-5 text-sm py-3 "
            >
              Pair Match Reward
            </button>
          </div>
        </div>

        <div className=" min-h-[100px] md:min-h-[200px] min-w-[200px] md:min-w-[200px]   flex-1 border border-[#C026D3] rounded-lg py-5 px-5 bg-[#00011B]">
          <img
            src={process.env.PUBLIC_URL + "/assets/R4.svg"}
            className="max-w-[80px] w-full mx-auto "
          />
          <h1 className="text-white text-center text-lg py-5">
            {fee[4] !== undefined ? fee[4] : 0}%
          </h1>
          <div className="text-center">
            <div className="bg-gradient-to-r from-[#157AC8] to-[#BC28D3] rounded-full text-white px-5 text-sm py-3 ">
              Staking APR
            </div>
          </div>
        </div>
        <div className="min-h-[100px] md:min-h-[200px] min-w-[200px] md:min-w-[200px]  flex-1 px-5 py-7  rounded-2xl  bg-gradient-to-r from-[#157AC8] to-[#BC28D3]">
          <img
            src={process.env.PUBLIC_URL + "/assets/R5.svg"}
            className="max-w-[80px] w-full mx-auto "
          />
          <h1 className="text-white text-center text-lg py-5">
            {userInfo.total !== undefined
              ? (userInfo.total / Math.pow(10, 18)).toFixed(2)
              : 0}
          </h1>
          <div className="text-center">
            <div className="bg-white text-[#C026D3] rounded-full  px-5 text-sm py-3 ">
              Total Reward
            </div>
          </div>
        </div>

        <div className="min-h-[100px] md:min-h-[200px] min-w-[200px] md:min-w-[200px]  flex-1 border border-[#C026D3] rounded-lg py-5 px-5 bg-[#00011B]">
          <img
            src={process.env.PUBLIC_URL + "/assets/R6.svg"}
            className="max-w-[80px] w-full mx-auto "
          />
          <h1 className="text-white text-center text-lg py-5">
            {fee[3] !== undefined ? fee[3] : 0}%
          </h1>
          <div className="text-center">
            <div className="bg-gradient-to-r from-[#157AC8] to-[#BC28D3] rounded-full text-white px-5 text-sm py-3">
              Stake Match Reward
            </div>
          </div>
        </div>
      </div>

      <div className="py-5 my-5">
        <img
          src={process.env.PUBLIC_URL + "/assets/pie.png"}
          className="max-w-[300px] w-full mx-auto"
        />
      </div>
      <div className=" text-white   my-5">
        <h6 className="text-lg text-white  border border-[#C026D3] rounded-t-lg text-center py-5 font-bold">
          Team Size
        </h6>

        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className=" w-full inline-block align-middle">
              <div className="overflow-scroll md:overflow-hidden  border data-table text-white rounded-b-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <tbody className="divide-y divide-gray-200">
                    <tr>
                      <td className="px-6 py-[2rem] text-md font-medium text-white whitespace-nowrap">
                        Particular
                      </td>
                      <td className="px-6 py-[2rem] text-sm text-white whitespace-nowrap">
                        Left
                      </td>
                      <td className="px-6 py-[2rem] text-sm text-white whitespace-nowrap">
                        Right
                      </td>
                      <td className="px-6 py-[2rem] text-sm text-white whitespace-nowrap">
                        Both
                      </td>
                    </tr>
                    <tr>
                      <td className="px-6 py-[2rem] text-sm font-medium text-white whitespace-nowrap">
                        Total
                      </td>
                      <td className="px-6 py-[2rem] text-sm text-white whitespace-nowrap">
                        {childCount.leftCount !== undefined
                          ? childCount.leftCount
                          : 0}
                      </td>
                      <td className="px-6 py-[2rem] text-sm text-white whitespace-nowrap">
                        {childCount.rightCount !== undefined
                          ? childCount.rightCount
                          : 0}
                      </td>
                      <td className="px-6 py-[2rem] text-sm text-white whitespace-nowrap">
                        {childCount.leftCount !== undefined
                          ? parseInt(childCount.leftCount) +
                            parseInt(childCount.rightCount)
                          : 0}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" text-white   my-5">
        <h6 className="text-lg text-white text-center  border border-[#C026D3] rounded-t-lg py-5 font-bold ">
          Stacking Amount
        </h6>
        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className=" w-full inline-block align-middle">
              <div className="overflow-scroll  md:overflow-hidden border data-table text-white rounded-b-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <tbody className="divide-y divide-gray-200">
                    <tr>
                      <td className="px-6 py-[2rem] text-sm font-medium text-white whitespace-nowrap">
                        Particular
                      </td>
                      <td className="px-6 py-[2rem] text-sm text-white whitespace-nowrap">
                        Left
                      </td>
                      <td className="px-6 py-[2rem] text-sm text-white whitespace-nowrap">
                        Right
                      </td>
                      <td className="px-6 py-[2rem] text-sm text-white whitespace-nowrap">
                        Match
                      </td>
                    </tr>
                    <tr>
                      <td className="px-6 py-[2rem] text-sm font-medium text-white whitespace-nowrap">
                        Total
                      </td>
                      <td className="px-6 py-[2rem] text-sm text-white whitespace-nowrap">
                        {userStakeInfo.leftStakeTotal !== undefined
                          ? userStakeInfo.leftStakeTotal / Math.pow(10, 18)
                          : 0}
                      </td>
                      <td className="px-6 py-[2rem] text-sm text-white whitespace-nowrap">
                        {userStakeInfo.rightStakeTotal !== undefined
                          ? userStakeInfo.rightStakeTotal / Math.pow(10, 18)
                          : 0}
                      </td>
                      <td className="px-6 py-[2rem] text-sm text-white whitespace-nowrap">
                        {userStakeInfo.leftStakeTotal !== undefined
                          ? userStakeInfo.leftStakeTotal >
                            userStakeInfo.rightStakeTotal
                            ? userStakeInfo.rightStakeTotal / Math.pow(10, 18)
                            : userStakeInfo.leftStakeTotal / Math.pow(10, 18)
                          : 0}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {isLoading && (
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className="flex max-w-[350px] h-screen w-[500px] items-center justify-center z-100">
              {/* <ReactLoading
                type={"bars"}
                color={"#2373c9"}
                height={100}
                width={100}
              /> */}
              <ThreeDots
                height={80}
                width={80}
                radius={9}
                color="white" // Color for the dots
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          </div>
        )}

        {showConfirmation && (
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
            <Popup
              topic="Claim Pair Match Reward"
              message="Do you want to claim the availavle tokens?"
              onConfirm={handlePairMatchConfirm}
              onCancel={handleCancelClick}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default HomeComponent;
