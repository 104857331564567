import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

export const MatchingReward = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(-1);
  };
  return (
    <>
      <div className="max-w-[500px] w-full mx-auto ">
        <div className="bg-gradient-to-r from-[#04021F] to-[#280941] text-white min-h-screen ">
          <NavLink to="/main">
            <img
              src={process.env.PUBLIC_URL + "/assets/left.png"}
              className="px-5 pt-4 cursor-pointer"
            />
          </NavLink>

          <h2 className="text-white text-center font-bold">Transfer</h2>
          <div className="max-w-[450px] w-full mx-auto py-5 md:px-0 px-4">
            <div className="flex items-center gap-4 flex-wrap py-2 px-4 bg-gradient-to-r from-[#157AC8] to-[#BC28D3] rounded-full">
              <div className="bg-white rounded-full h-[50px] w-[50px] grid place-items-center px-3">
                <img
                  src={process.env.PUBLIC_URL + "/assets/matching.svg"}
                  className="max-w-[30px] w-full mx-auto"
                />
              </div>
              <div>
                <h3 className="font-bold">Matching Reward</h3>
                <p>Amount:0</p>
              </div>
            </div>

            <div className="border border-[#C026D3] rounded-2xl py-5 px-5 bg-[#00011B] my-[3rem]">
              <h1 className="px-4 font-bold">Transaction History</h1>
              <div className="flex gap-2 items-start justify-between border max-w-[400px] w-full  bg-[#00011B] border-[#0682C7] rounded-full my-5 px-5 py-3">
                <input
                  type="text"
                  placeholder="Quick Search"
                  className="bg-transparent w-[400px]"
                />
                <img src={process.env.PUBLIC_URL + "/assets/search.png"} />
              </div>
              <div className="flex flex-col">
                <div className="overflow-x-auto">
                  <div className=" w-full inline-block align-middle">
                    <div className="overflow-scroll md:overflow-auto border info-table text-white rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200">
                        <tbody className="divide-y divide-gray-200">
                          <tr>
                            <td className="px-3 py-3 text-sm font-medium  border-r text-white whitespace-nowrap">
                              SR. No.
                            </td>
                            <td className="px-3 py-3 text-sm text-white  border-r  whitespace-nowrap">
                              Date and Time
                            </td>
                            <td className="px-3 py-3 text-sm text-white  border-r  whitespace-nowrap">
                              Amount
                            </td>
                            <td className="px-3 py-3 text-sm text-white  border-r  whitespace-nowrap">
                              Wallet
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
