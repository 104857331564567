import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
// import ReactLoading from "https://cdn.skypack.dev/react-loading@2.0.3";
import { ThreeDots } from "react-loader-spinner";

import {
  MainContractABI,
  MainContractAddress,
  DLUContractAddress,
  DLUABI,
} from "./../config";
import { useBalance, useAccount, useConnect } from "wagmi";
import Web3 from "web3";
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Loading } from "./Loader/Loading";

const people = [{ name: "true" }, { name: "false" }];
const Register = () => {
  const [selectedOption, setSelectedOption] = useState(people[0]);

  // Update selectedOption based on Listbox selection
  const handleListboxChange = (selected) => {
    setSelectedOption(selected);
  };

  let web3 = new Web3(window.ethereum);
  const [isLoading, setIsLoading] = useState(false);
  const [sponserId, setSponserId] = useState(MainContractAddress);
  const [parentId, setParentId] = useState("");
  const [username, setUserName] = useState("");
  const [isLeft, setIsLeft] = useState(false);
  // const [parent, setParent] = useState("");
  // const [sponsor, setSponsor] = useState("");
  // const [leftPosition, setLeftPosition] = useState("");

  const [isUserExist, setIsUserExist] = useState(false);

  const { address, isDisconnected } = useAccount();
  const navigate = useNavigate();

  let MainContract = new web3.eth.Contract(
    MainContractABI,
    MainContractAddress
  );

  let DLUContract = new web3.eth.Contract(DLUABI, DLUContractAddress);

  const checkApproval = async () => {
    const approvel = DLUContract.methods
      .allowance(address, MainContractAddress)
      .call();
    console.log("approvel : ", await approvel);
    return await approvel;
  };

  const handleRegister = async () => {
    if (username === "") {
      alert("Please enter a username");
      return;
    }
    setIsLoading(true);
    // First Apporve from the DLU Token
    console.log(
      "parseFloat((await checkApproval()) / Math.pow(10, 18) ",
      parseFloat((await checkApproval()) / Math.pow(10, 18)),
      typeof parseFloat((await checkApproval()) / Math.pow(10, 18))
    );

    const approvalAmount = await checkApproval();
    const approvalInEther = parseFloat(approvalAmount / Math.pow(10, 18));

    if (approvalInEther < 20) {
      // if (parseFloat((await checkApproval()) / Math.pow(10, 18) < 20)) {
      try {
        const tokenApprove = DLUContract.methods.approve(
          MainContractAddress,
          web3.utils.toWei("10000000000000000000000", "ether")
        );

        let encoded_tx = tokenApprove.encodeABI();
        let gasPrice = await web3.eth.getGasPrice();
        console.log("gasPrice : ", gasPrice);
        let gasLimit = await web3.eth.estimateGas({
          gasPrice: web3.utils.toHex(gasPrice),
          to: DLUContractAddress,
          from: address,
          data: encoded_tx,
        });
        let trx = await web3.eth.sendTransaction({
          gasPrice: web3.utils.toHex(gasPrice),
          gas: web3.utils.toHex(gasLimit),
          to: DLUContractAddress,
          from: address,
          data: encoded_tx,
        });
      } catch (error) {
        {
          setIsLoading(false);
          let err = error.data ? error.data.message : error.message;
          alert(err);
          console.log(err);
        }
      }
    }
    try {
      console.log("regi data : ", sponserId, parentId, address);
      //  calling Join Function
      // const tx = await MainContract.methods.Join(
      //   sponsor !== null ? sponsor : sponserId,
      //   parent !== null ? parent : parentId,
      //   selectedOption.name == "true" ? true : false,
      //   username
      // );
      const tx = await MainContract.methods.Join(
        sponserId,
        parentId,
        selectedOption.name == "true" ? true : false,
        username
      );
      let encoded_tx = tx.encodeABI();
      let gasPrice = await web3.eth.getGasPrice();
      let gasLimit = await web3.eth.estimateGas({
        gasPrice: web3.utils.toHex(gasPrice),
        to: MainContractAddress,
        from: address,
        data: encoded_tx,
      });
      let trx = await web3.eth.sendTransaction({
        gasPrice: web3.utils.toHex(gasPrice),
        gas: web3.utils.toHex(gasLimit),
        to: MainContractAddress,
        from: address,
        data: encoded_tx,
      });
    } catch (error) {
      setIsLoading(false);
      let err = error.data ? error.data.message : error.message;
      alert(err);
      console.log(err);
    }
    setIsLoading(false);
    navigate("/register");
  };

  //fetch data about the  token
  const getData = async () => {
    setIsLoading(true);
    try {
      const isReferralLink = await MainContract.methods
        .isReferralLink(address)
        .call();
      setIsUserExist(isReferralLink);

      // Get the current URL
      const currentURL = window.location.href;

      // Create a URLSearchParams object from the URL
      const searchParams = new URLSearchParams(currentURL);

      // // Access and parse the query parameters
      // const parentAddress = searchParams.get("parent");
      // setParent(parentAddress);
      // const sponsorAddress = searchParams.get("sponsor");
      // setSponsor(sponsorAddress);
      // const leftPosition = searchParams.get("leftPosition");
      // setLeftPosition(leftPosition ? true : false);

      // console.log("parent, leftPositon : ", parentAddress, leftPosition);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getData();

      const isReferralLink = await MainContract.methods
        .isReferralLink(address)
        .call();
      if (isReferralLink) {
        navigate("/main");
      } else {
        navigate("/register");
      }
    };

    fetchData();
  }, [address, isDisconnected, isUserExist]);

  {
    console.log(
      "selectedOption.name 888: ",
      selectedOption.name,
      typeof selectedOption.name
    );
  }

  return (
    <>
      <div className="max-w-[500px] w-full mx-auto register ">
        <div className="bg-gradient-to-r from-[#04021F] to-[#280941] text-white min-h-screen ">
          <NavLink to="/">
            <img
              src={process.env.PUBLIC_URL + "/assets/left.png"}
              className="px-5 pt-4"
            />
          </NavLink>

          <h2 className="text-white text-center font-bold ">Register</h2>
          <form className=" mx-auto max-w-[400px] py-5 px-3">
            <div className="my-4">
              <label className="pb-3">Sponser Address</label>
              <br />
              <input
                type="text"
                // value={sponsor !== "" ? sponsor : sponserId}
                value={sponserId}
                placeholder="Sponser Address"
                className="rounded-lg px-5 py-3 w-full my-4"
                onChange={(e) => setSponserId(e.target.value)}
              ></input>
            </div>

            <div className="my-4">
              <label className="pb-3">Parent Address</label>
              <br />
              <input
                type="text"
                value={parentId}
                placeholder="Parent Address"
                className="rounded-lg px-5 py-3 w-full my-4"
                onChange={(e) => setParentId(e.target.value)}
              ></input>
              {/* <p className="mt-1">Pay 50.00 USDT For Registration</p> */}
            </div>

            <div className="my-4">
              <label className="pb-3">Username</label>
              <br />
              <input
                type="text"
                placeholder="Name"
                className="rounded-lg px-5 py-3 w-full my-4"
                onChange={(e) => setUserName(e.target.value)}
              ></input>
            </div>

            <div className="my-4">
              <label className="pb-3">Parent Left Side?</label>
              <br />

              <Listbox
                value={selectedOption.name}
                onChange={handleListboxChange}
              >
                <div className="relative mt-1">
                  <Listbox.Button
                    className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
                  >
                    <span className="block truncate text-[#9CA39A]">
                      {selectedOption.name}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {people.map((person, personIdx) => (
                        <Listbox.Option
                          key={personIdx}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active
                                ? "bg-[#FFFFFF33] text-[#9CA39A]"
                                : "text-gray-900"
                            }`
                          }
                          value={person}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                {person.name}
                              </span>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>

              <p className="mt-1 text-sm">Pay 0.0 BNCOIN For Registration</p>
            </div>
          </form>
          <div className="text-center py-5">
            <button
              onClick={handleRegister}
              className="text-white  px-3 max-w-[300px] w-full mx-auto py-3 rounded-full  bg-gradient-to-r from-[#157AC8] to-[#BC28D3] font-bold"
            >
              Register
            </button>
          </div>
        </div>

        {isLoading && (
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className="flex max-w-[350px] h-screen w-[500px] items-center justify-center z-50">
              {/* <ReactLoading
                type={"bars"}
                color={"#2373c9"}
                height={100}
                width={100}
              /> */}

              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="white"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Register;
