import React from "react";
import { Route, Routes } from "react-router-dom";
import MainLayout from "./Layout/MainLayout";
import AppScreen from "./components/AppScreen";
import ConnectWallet from "./components/ConnectWallet";
import "./App.css";
import Transfer from "./components/Transfer";
import DirectReward from "./components/Rewards/DirectReward";
import LevelReward from "./components/Rewards/LevelReward";
import { MatchingReward } from "./components/Rewards/MatchingReward";
import GenalogyTree from "./components/Tree/GenalogyTree";
import TreeChart from "./components/TreeChart";
import Register from "./components/Register";
import Stacking from "./components/Stacking";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
    // return "We are currently down for Maintenance";
  
  return (
    <>
      <Routes>
        <Route path="/" element={<ConnectWallet />}></Route>
        <Route path="/direct-reward" element={<DirectReward />}></Route>
        <Route path="/level-reward" element={<LevelReward />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/matching-reward" element={<MatchingReward />}></Route>
        <Route path="/stacking" element={<Stacking />}></Route>
        <Route path="/main" element={<AppScreen />}></Route>
        {/* <Route path="/demo" element={<TreeChart/>}></Route>  */}
      </Routes>
    </>
  );
}

export default App;
