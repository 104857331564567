import React, { useEffect, useState, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
// import ReactLoading from "https://cdn.skypack.dev/react-loading@2.0.3";
import { ThreeDots } from "react-loader-spinner";
import { MainContractABI, MainContractAddress } from "../../config";
import Web3 from "web3";
import copy from "copy-to-clipboard";
import { useAccount } from "wagmi";
import { format } from "date-fns";

const DirectReward = () => {
  const [userInfo, setUserInfo] = useState("");
  const [directReward, setDirectReward] = useState("");
  const { address, isDisconnected } = useAccount();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(-1);
  };

  let web3 = new Web3(window.ethereum);
  let MainContract = new web3.eth.Contract(
    MainContractABI,
    MainContractAddress
  );

  const convertDateTime = (epochTimestamp) => {
    // Convert the epoch timestamp to milliseconds
    const timestampInMilliseconds = epochTimestamp * 1000;

    // Create a Date object from the timestamp
    const date = new Date(timestampInMilliseconds);

    // Format the date using date-fns
    const formattedDateTime = format(date, "dd-MMMM-yyyy hh:mm a");

    // Return Format
    return formattedDateTime;
  };

  //fetch data about the  token
  const getData = async () => {
    setIsLoading(true);
    try {
      // get username
      let user = await MainContract.methods.User(address).call();
      setUserInfo(user);
      let directReward = await MainContract.methods
        .getDirectRewardTable(address)
        .call();
      setDirectReward(directReward);
      console.log("directReward : ", directReward);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, [address, isDisconnected]);
  {
    console.log("userInfo : ", userInfo);
  }

  return (
    <>
      <div className="max-w-[500px] w-full mx-auto ">
        <div className="bg-gradient-to-r from-[#04021F] to-[#280941] text-white min-h-screen ">
          <img
            src={process.env.PUBLIC_URL + "/assets/left.png"}
            className="px-5 pt-4 cursor-pointer"
            onClick={handleNavigate}
          />

          <h2 className="text-white text-center font-bold">Transfer</h2>
          <div className="max-w-[450px] w-full mx-auto py-5 md:px-0 px-4">
            <div className="flex items-center gap-4 flex-wrap py-2 mb-5  px-4 bg-gradient-to-r from-[#157AC8] to-[#BC28D3] rounded-full">
              <div className="bg-white rounded-full h-[50px] w-[50px] grid place-items-center px-3">
                <img
                  src={process.env.PUBLIC_URL + "/assets/direct.svg"}
                  className="max-w-[90px] w-full mx-auto"
                />
              </div>
              <div>
                <h3 className="font-bold">Direct Reward</h3>
                <p>Amount: {(userInfo.direct / Math.pow(10, 18)).toFixed(2)}</p>
              </div>
            </div>

            <div className="border border-[#C026D3] rounded-2xl py-5 px-5 bg-[#00011B] my-[3rem]">
              <h1 className="px-4 font-bold">Transaction History</h1>
              <div className="flex gap-2 items-start justify-between border max-w-[400px] w-full  bg-[#00011B] border-[#0682C7] rounded-full my-5 px-5 py-3">
                <input
                  type="text"
                  value={address ? address : ""}
                  placeholder="Quick Search"
                  className="bg-transparent w-[400px]"
                />
                <img src={process.env.PUBLIC_URL + "/assets/search.png"} />
              </div>
              <div className="reward-table py-5">
                <div className="flex flex-col">
                  <div className="overflow-x-auto">
                    <div className=" w-full inline-block align-middle">
                      <div className="overflow-scroll md:overflow-auto border info-table text-white rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <tbody className="divide-y divide-gray-200">
                            <tr>
                              <td className="px-3 py-3 text-sm font-medium  border-r text-white whitespace-nowrap">
                                SR. No.
                              </td>
                              <td className="px-3 py-3 text-sm text-white  border-r  whitespace-nowrap">
                                Date and Time
                              </td>
                              <td className="px-3 py-3 text-sm text-white  border-r  whitespace-nowrap">
                                Amount
                              </td>
                              <td className="px-3 py-3 text-sm text-white  border-r  whitespace-nowrap">
                                Wallet Address
                              </td>
                            </tr>
                            {directReward ? (
                              directReward.map((item, key) => (
                                <tr key={item.id}>
                                  <td className="px-3 py-3 text-sm font-medium border-r text-white whitespace-nowrap">
                                    {key + 1}
                                  </td>
                                  <td className="px-3 py-3 text-sm text-white border-r whitespace-nowrap">
                                    {convertDateTime(item.date)}
                                  </td>
                                  <td className="px-3 py-3 text-sm text-white border-r whitespace-nowrap">
                                    {(item.amount / Math.pow(10, 18)).toFixed(
                                      2
                                    )}
                                  </td>
                                  <td className="px-3 py-3 text-sm text-white border-r whitespace-nowrap">
                                    {item.wallet}
                                    {/* {item.wallet &&
                                      item.wallet?.slice(0, 4) +
                                        "****" +
                                        item.wallet?.slice(-4)} */}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="4">Loading data...</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isLoading && (
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className="flex max-w-[350px] h-screen w-[500px] items-center justify-center z-50">
              {/* <ReactLoading
                type={"bars"}
                color={"#2373c9"}
                height={100}
                width={100}
              /> */}
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="white"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DirectReward;
