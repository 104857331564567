import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount } from "wagmi";
import Web3 from "web3";
import { MainContractABI, MainContractAddress } from "../config";
const ConnectWallet = () => {
  const { isConnected, address } = useAccount();
  const { isOpen, open, close, setDefaultChain } = useWeb3Modal();
  const [isUserExist, setIsUserExist] = useState(false);
  const navigate = useNavigate();
  let web3 = new Web3(window.ethereum);

  // get contract
  let MainContract = new web3.eth.Contract(
    MainContractABI,
    MainContractAddress
  );

  //fetch data about the  token
  const getData = async () => {
    try {
      const isReferralLink = await MainContract.methods
        .isReferralLink(address)
        .call();
      setIsUserExist(isReferralLink);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    if (isConnected) {
      navigate("/register");
    }
  }, [address, isConnected, isUserExist]);

  return (
    <div>
      <div className="connect-wallet  max-w-[500px] w-full mx-auto min-h-screen relative pt-5">
        <div>
          <div>
            <img
              src={process.env.PUBLIC_URL + "/assets/fire.svg"}
              className="max-w-[350px] w-full mx-auto connect-img"
            />
          </div>

          <div className="mt-10">
            <div className="">
              <h2 className=" text-4xl text-white text-center text-4xl font-bold">
                Welcome to DeNet
              </h2>
              <p className="uppercase text-lg  text-center text-[#9999A4] px-3 py-4 font-bold max-w-[300px] w-full mx-auto">
                A new age of network networking
              </p>
            </div>

            <div className="text-center pt-5 pb-3">
              <button
                onClick={() => {
                  open();
                }}
                className="text-white   my-5 px-3 max-w-[300px] w-full mx-auto py-3 rounded-full  bg-gradient-to-r from-[#157AC8] to-[#BC28D3] font-bold"
              >
                {isConnected ? "Disconnect" : "Connect to Wallet"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectWallet;
